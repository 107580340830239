import { JSX } from "preact";
import { useState } from "preact/hooks";
import { ExpandIcon, CollapseIcon } from "../../components/ExpandCollapseIcons.tsx";

interface PanelLeftHeaderProps {
  searchQuery: { value: string };
  onExpandAll: () => void;
  onCollapseAll: () => void;
  onSearch: (value: string) => void;
  showProfiles: boolean;
  onToggleProfiles: () => void;
}

export default function PanelLeftHeader({
  searchQuery,
  onExpandAll,
  onCollapseAll,
  onSearch,
  showProfiles,
  onToggleProfiles,
}: PanelLeftHeaderProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  // Handle expand/collapse toggle
  const handleToggle = () => {
    if (isExpanded) {
      onCollapseAll();
    } else {
      onExpandAll();
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <div class="sticky top-0 z-10 bg-slate-100 shadow-md p-2 flex flex-col space-y-4">

      {/* Full-text search over endpoints and profiles with expand/collapse button */}
      <div class="flex items-center space-x-2 sm:space-x-1">
        <button
          class="group bg-slate-300 text-white px-3 py-1 rounded hover:bg-slate-200 sm:px-2 sm:py-1"
          onClick={handleToggle}
        >
          {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
        </button>
        <input
          type="text"
          placeholder="Full-Text Search of Endpoints..."
          class="border px-4 py-2 rounded focus:outline-none focus:ring-0 flex-grow sm:px-2 sm:py-1 text-sm"
          value={searchQuery.value}
          onInput={(e) => onSearch((e.target as HTMLInputElement).value)}
        />
      </div>

      {/* Checkbox to toggle profile display */}
      <div class="flex items-center space-x-2 sm:space-x-1 pl-1">
        <input
          type="checkbox"
          id="showProfilesCheckbox"
          checked={showProfiles}
          onChange={onToggleProfiles}
          class="transform scale-125 sm:scale-100"
        />
        <label for="showProfilesCheckbox" class="text-slate-600 text-sm sm:text-xs">... in Description & Profiles</label>
      </div>
    </div>
  );
}
